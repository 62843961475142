export const de_CH = {
  customer_type: 'Kundenart',
  manage_appointment_now: 'Termin jetzt unterhalten',
  maintenance_page_title: 'Calenso wird gerade für dich verbessert',
  maintenance_page_subtitle: 'Calenso wird gerade auf die neuste Version aktualisiert. Bitte haben sie etwas Geduld und schauen sie in ein paar Minuten nochmals rein.',
  maintenance_page_button: 'Zur Statusseite',
  load_more: 'Mehr laden',
  show_more: 'Mehr anzeigen',
  internal_label: 'Intern',
  check_availability_for_anyone: 'Verfügbarkeiten für alle Ressourcen prüfen',
  store_not_found: 'Keine Filiale gefunden',
  appointment: 'Termine',
  event: 'Gruppentermine',
  resource: 'Ressourcen',
  morgen: 'morgen',
  vormittag: 'vormittag',
  mittag: 'mittag',
  nachmittag: 'nachmittag',
  abend: 'abend',
  nacht: 'nacht',
  free: 'kostenlos',
  hoursShort: '',
  minutesShort: 'Min',
  mondayLong: 'Montag',
  day1: 'MO',
  tuesdayLong: 'Dienstag',
  day2: 'DI',
  wednesdayLong: 'Mittwoch',
  day3: 'MI',
  thursdayLong: 'Donnerstag',
  day4: 'DO',
  fridayLong: 'Freitag',
  day5: 'FR',
  saturdayLong: 'Samstag',
  day6: 'SA',
  sundayLong: 'Sonntag',
  day0: 'SO',
  Januar: 'Januar',
  Februar: 'Februar',
  März: 'März',
  April: 'April',
  Mai: 'Mai',
  Juni: 'Juni',
  Juli: 'Juli',
  August: 'August',
  September: 'September',
  Oktober: 'Oktober',
  November: 'November',
  Dezember: 'Dezember',
  "shortMonths": {
    "Januar": "Jan",
    "Februar": "Feb",
    "März": "Mär",
    "April": "Apr",
    "Mai": "Mai",
    "Juni": "Jun",
    "Juli": "Jul",
    "August": "Aug",
    "September": "Sep",
    "Oktober": "Okt",
    "November": "Nov",
    "Dezember": "Dez"
  },
  du: 'Du',
  sie: 'Sie',
  havingFormDu: 'hast',
  havingFormSie: 'haben',
  yes: "Ja",
  no: "Nein",
  confirm: {
    title: 'Buchung bestätigen',
    confirmationText: 'Du hast {{ cartLength }} Termine in deinem Warenkorb. Möchtest Du all diese Termine verbindlich buchen?',
    confirmationTextSie: 'Sie haben {{ cartLength }} Termine in Ihren Warenkorb. Möchtest Sie all diese Termine verbindlich buchen?',
    ok: 'Buchen',
    cancel: 'Abbrechen'
  },
  internal: {
    search_customer: 'Bestehenden Kunden suchen und Formular vorausfüllen',
    selectPlaceholder: 'Kunde suchen',
    customerDetails: 'Kundendetails',
    noCustomer: 'Es sind keine Kunden konfiguriert',
    create_customer: 'Kunde erstellen'
  },
  common: {
    report_bug: 'Bug melden',
    subscription_error_title: 'Kein Abonnement!',
    subscription_error_desc: 'Leider konnte kein Abonnement gefunden werden. Bitte kontaktieren den Calenso-Support.',
    next: 'Weiter',
    previous: 'Zurück',
    reset: 'Applikation zurücksetzen',
    resetSuccess: 'Die Buchungssoftware wurde erfolgreich zurückgesetzt. Temporär gespeicherte Daten sind nun nicht mehr vorhanden.',
    loading: 'Lade ...',
    durationHour: ' Std.',
    durationMinute: ' Min.',
    clock: 'Uhr.',
    disclaimer: 'Dieser Service wird von <a href=\'https://www.calenso.com\' class=\'cal-link\' target=\'_blank\'>Calenso</a> bereitgestellt.',
    book: 'Buchen',
    choseBookType: 'Buchungstyp wählen',
    company: 'Firma',
    prename: 'Vorname',
    lastname: 'Nachname',
    email: 'E-Mail',
    phone: 'Mobiltelefon',
    address: 'Adresse',
    createAccount: 'Kundenkonto anlegen (Benutzerkonto mit allen Anmeldungen)',
    noDateError: 'Der gewünschte Termin wurde von jemand anderem gebucht, gehen sie zurück und wählen Sie einen neuen Termin.',
    devNotice: 'Das Buchungswidget ist mit dem DEV-System verbunden. Bitte aufpassen!',
    store_access_warning: 'Der Zugriff auf den Browser-Speicher ist aufgrund Ihrer Datenschutzeinstellungen eingeschränkt. Es stehen ggf. nicht alle Funktionen des Buchungswidgets zur Verfügung.',
    save: "Speichern",
    cancel: "Abbrechen",
    additionalParticipantsUpTo: "Sie können bis zu {{ number }} Gäste hinzufügen",
    additionalGuest: 'Zusätzliche Teilnehmer',
    addAdditionalGuest: 'Teilnehmer hinzufügen',
    additionalGuestCartLabel: '{{ number }} zusätzlicher Gast',
    additionalGuestsCartLabel: '{{ number }} zusätzliche Gäste',
    widget_disabled: 'Das Buchungswidget ist zurzeit deaktiviert',
    widget_disabled_description: 'Das Buchungs-Widget ist vorübergehend deaktiviert. Bitte kontaktieren Sie den Calenso-Support (Fehler-Code: PMT-01).',
    no_workers_widget_disabled_description: 'Es können aktuell keine Termine gebucht werden, da keine Ressource aktiviert ist.',
    workers_not_available: 'Keine buchbare Dienstleistung gefunden',
    workers_not_available_description: 'Es wurde keine buchbare Dienstleistung erfasst. Bitte stelle sicher, dass mindestens eine Dienstleistung vorhanden ist und diese einer buchbaren Ressource zugewiesen ist (Fähigkeit). Das Erfassen einer Dienstleistungskategorie reicht nicht aus, um buchbar zu sein.',
    price_from_label: 'ab',
    close: 'Schliessen',
    configuration_error: 'Konfigurationsfehler',
    resource_is_not_bookable: 'Die selektierte Ressource ist nicht buchbar.',
    verification_token_missing: 'Das Buchungswidget wird im internen Modus verwendet und benötigt somit einen Verifizierungstoken. Bitte kontaktiere den Calenso-Support.',
    invalid_verification_code: 'Der Zugriff auf das Widget im internen Modus erfordert einen gültigen Token. Stelle sicher, dass der Token gültig ist, und versuche es erneut.',
    internal_disabled: 'Das interne Buchungswidget ist deaktiviert.',
    evnet_disabled_error: 'Gewünschtes Ereignis ist von Partner deaktiviert, bitte kontaktieren Sie Ihren Administrator.',
    conference_invalid_link: "Es scheint, dass der bereitgestellte Konferenzlink ungültig ist. Bitte überprüfe den Link und versuche es erneut.",
    conference_expired_link: "Es tut uns leid, aber dieser Konferenzlink ist abgelaufen. Bitte kontaktiere den Organisator für weitere Hilfe.",
    conference_not_belonging: "Hoppla, es sieht so aus, als wäre diese Konferenz mit einem anderen Partner verknüpft.",
    conference_booked: "Hoppla, es scheint, als wäre dieser Konferenzlink bereits verwendet und gebucht worden. Wenn du Fragen hast oder Hilfe benötigst, kannst du dich gerne an die Organisatoren wenden.",
    conference_invalid_configurations: "Die Konferenz ist aufgrund ungültiger interner Konfigurationen nicht verfügbar. Für weitere Hilfe, bitte kontaktiere den Veranstalter.",
    location: "Standort"
  },
  appointments: {
    step1: {
      distance_from_store: '{{ distance }} km entfernt',
      search_store_postcode_helper_text: 'PLZ eingeben, um nächstgelegene Filiale zu suchen',
      search_services_helper_text: 'Suchbegriff eingeben, um nach Dienstleistungen zu suchen',
      title: 'Auswahl',
      chooseStore: 'Filiale auswählen',
      chooseService: 'Dienstleistung auswählen',
      nextButton: 'Weiter',
      search_store_by_postcode_placeholder: 'Postleitzahl einfügen, um korrekte Filiale zu suchen',
      create_lead: 'Gelegenheit MH',
      expert_cap_button_label: 'Am Experten VVB zuweisen',
      appointment_booking_not_available_for_zipcode: 'Für diese Postleitzahl ist die Terminbuchung leider nicht möglich. Erstelle einen neuen Kunden, damit ein CAP diesen per Telefon kontaktieren kann.'
    },
    step2: {
      title: 'Ressource',
      chooseEmployee: 'Ressource auswählen',
      name: 'Name',
      function: 'Funktion',
      noSpecificWorker: 'Egal wer',
      noSpecificFunction: 'Alle Fachbereiche',
      nextButton: 'Datum/Zeit auswählen'
    },
    step3: {
      title: 'Datum',
      chooseDate: 'Gewünschtes Datum auswählen',
      morning: 'Morgen',
      forenoon: 'Vormittag',
      noon: 'Mittag',
      afternoon: 'Nachmittag',
      evening: 'Abend',
      night: 'Nacht',
      infoMsg: 'Bitte beachte, es ist möglich mehrere Termine gleichzeitig zu buchen. Wähle dazu einfach die gewünschten Termine via Checkbox. Die Termine werden dann in den Warenkorb gelegt.',
      appointment: 'Termin',
      employee: 'Ressource',
      proposals: 'Terminvorschläge',
      noFreeAppointments: 'An diesem Tag gibt es leider keine freien Termine mehr!',
      nextButton: 'Weiter',
      cartNotSupported: 'Dieser Anbieter unterstützt nur eine Slotregistrierung pro Buchung.',
      cartSupported: 'Dieser Anbieter unterstützt Mehrfachregistrierungen pro Buchung.',
      workerSelectLable: 'Persönlicher Berater auswählen:',
      workerSelectLinkPrefix: 'Kein Termin bei Ihrem Berater gefunden?',
      workerSelectShowAllLinkPostfix: 'Alle Berater anzeigen',
      workerSelectHideAllLinkPostfix: 'Alle Berater ausblenden'
    },
    step4: {
      comment_helper_text: 'Maximal 2000 Zeichen',
      title: 'Abschluss',
      summary: 'Zusammenfassung',
      company: 'Firma',
      store: 'Filiale',
      employee: 'Ressource',
      bookedServices: 'Dienstleistung',
      duration: 'Dauer',
      price: 'Preis',
      finalPrice: 'Kosten',
      total: 'Gesamt',
      yourData: 'Persönliche Daten',
      disclaimer: 'Bitte geben Sie Ihre Kontaktdaten ein. Alle mit einem * gekennzeichneten Felder sind Pflichtfelder.',
      prename: {
        label: 'Vorname',
        placeholder: 'Vorname',
        error: {
          minChars: 'Der Vorname muss mindestens {{ requiredLength }} Zeichen enthalten.',
          required: 'Bitte einen Vorname angeben.'
        }
      },
      lastname: {
        label: 'Nachname',
        placeholder: 'Nachname',
        error: {
          minChars: 'Der Nachname muss mindestens {{ requiredLength }} Zeichen enthalten.',
          required: 'Bitte einen Nachname angeben.'
        }
      },
      phone: {
        label: 'Mobiltelefon',
        placeholder: '+4179xxxxxxx',
        error: {
          pattern: 'Die Telefonnummer darf nur Zahlen enthalten.',
          minChars: 'Die Telefonnummer muss mindestens {{ requiredLength }} Zeichen beinhalten.',
          required: 'Bitte Telefonnummer angeben.'
        }
      },
      eMail: {
        label: 'E-Mail',
        error: {
          pattern: 'Bitte eine gültige E-Mail Adresse angeben.',
          minChars: 'Die E-Mail Adresse muss mindestens {{ requiredLength }} Zeichen lang sein.',
          required: 'Bitte E-Mail Adresse angeben.'
        }
      },
      message: {
        label: 'Nachricht',
        placeholder: ''
      },
      internal_comment: {
        label: 'Interner Kommentar',
        placeholder: 'Kommentare für den internen Gebrauch hinzufügen',
        comment_helper_text: 'Maximal 5000 Zeichen'
      },
      company_name: {
        label: 'Name des Unternehmens',
        error: {
          minChars: 'Dieses Feld benötigt mindestens {{ requiredLength }} Zeichen.'
        }
      },
      customFields: {
        defaultOption: 'Bitte eine Option auswählen',
        error: {
          maxChars: 'Dieses Feld erlaubt maximal {{ requiredLength }} Zeichen.',
          minChars: 'Dieses Feld benötigt mindestens {{ requiredLength }} Zeichen.',
          required: 'Dieses Feld wird zwingend benötigt.',
          questionisRequired: "{{ text }} ist verpflichtend",
          checkboxisRequired: "Dieses Kontrollkästchen muss ausgewählt werden, um fortzufahren."
        }
      },
      cart: {
        emptyCartWarning: 'Zurzeit gibt es keine Termine im Warenkorb.',
        removeIcon: 'Termin aus Warenkorb entfernen',
        waiting_list: 'Warteliste'
      },
      numberSelect: {
        increase: 'Wert erhöhen',
        decrease: 'Wert verringern'
      }
    },
    finalpage: {
      successTitle: 'Buchung erfolgreich',
      successMessageDu: 'Die Terminbuchung wurde erfolgreich übermittelt. Eine Bestätigung wurde entweder via E-Mail oder via SMS versandt. <br><br>Wenn Du aus irgendwelchen Gründen nicht am Event teilnehmen kannst, bitten wir Dich, die Teilnahme via Stornierungslink im E-Mail oder im SMS abzusagen.',
      successMessageSie: 'Die Terminbuchung wurde erfolgreich übermittelt. Eine Bestätigung wurde entweder via E-Mail oder via SMS versandt. <br><br>Wenn Sie aus irgendwelchen Gründen nicht am Event teilnehmen können, bitten wir Sie, ihre Teilnahme via Stornierungslink im E-Mail oder im SMS abzusagen.',
      errorTitle: 'Es ist ein Fehler aufgetreten.',
      errorMessage: 'Der Termin konnte leider nicht gebucht werden. Bitte versuch es erneut.',
      errorMessageDu: 'Der Termin konnte leider nicht gebucht werden. Bitte versuch es erneut.',
      errorMessageSie: 'Der Termin konnte leider nicht gebucht werden. Bitte versuchen Sie es erneut.',
      bookNextAppointment: 'Einen weiteren Termin buchen',
      bookAppointmentAgain: 'Zurück gehen'
    },
    error: {
      noworkers: 'Dieser Anbieter hat leider keine buchbaren Ressourcen definiert.'
    }
  },
  events: {
    list: "Liste",
    grid: "Raster",
    noEvents: "Der Anbieter hat noch keine Events erfasst.",
    hidden_events_message: "Die Ereignisse dieses Partners sind verborgen. Bitte benutzen Sie den direkten Link zu der Veranstaltung, die Sie von dem Partner erhalten haben, um sich anzumelden.",
    filter_result_null: "Es gibt keine Ergebnisse, die deinen Kriterien entsprechen.",
    step1: {
      title: "Events",
      moreInformation: "Zeit auswählen",
      noFreeSlots: "Keine freien Slots mehr",
      noEvents: "Es wurden noch keine Events erstellt.",
      showDescription: "Ganze Beschreibung anzeigen",
      hideDescription: "Beschreibung verstecken",
      book: "Buchen",
      noPlaceDefined: "Kein Ort definiert",
      store_filter: "Standort-Filter",
      category_filter: "Kategorien-Filter",
      all_categories: "Alle"
    },
    step2: {
      title: 'Zeitfenster',
      slots: 'Zeitfenster',
      chooseDate: 'Gewünschtes Datum auswählen',
      booked: 'Gebucht',
      capacity: 'Verfügbar',
      noFreeDates: 'Für diesen Partner gibt es keine freien Events mehr',
      bookedOut: 'Dieses Zeitfenster ist bereits ausgebucht',
      cartNotSupported: 'Dieser Anbieter unterstützt nur eine Slotregistrierung pro Buchung.',
      cartSupported: 'Dieser Anbieter unterstützt Mehrfachregistrierungen pro Buchung.',
      alreadyBookedByUser: 'Du hast dieses Zeitfenster bereits gebucht. Falls du anderer Meinung bist, kannst du die Applikation zurücksetzen.',
      waitinglist: 'Warteliste: {{ capacity }} frei',
      fully_booked: "Ausgebucht",
      regular_count_singular: '<b>1</b> freier Platz',
      regular_count_plural: '<b>{{ number }}</b> freie Plätze',
      waiting_count_singular: "1 freier Wartelistenplatz",
      waiting_count_plural: "{{ number }} freie Wartelistenplätze",
      free_seats: 'Freie Plätze',
      free_waiting_seats: 'Freie Plätze auf der Warteliste',
      multiday_group_appointment: "Dies ist ein mehrtägiger Gruppentermin"
    },
    step3: {
      title: 'Zusammenfassung',
      summary: 'Zusammenfassung',
      company: 'Firma',
      store: 'Filiale',
      employee: 'Ressource',
      bookedEvent: 'Dienstleistung',
      date: 'Datum',
      time: 'Zeit',
      price: 'Preis',
      finalPrice: 'Kosten',
      total: 'Gesamt',
      yourData: 'Ihre Daten',
      disclaimer: 'Bitte geben Sie Ihre Kontaktdaten ein. Alle mit einem * gekennzeichneten Felder sind Pflichtfelder.',
      cart: {
        emptyCartWarning: 'Zurzeit gibt es keine Events im Warenkorb.',
        waiting_list: 'Warteliste'
      }
    },
    finalpage: {
      successTitle: 'Anmeldung erfolgreich',
      successMessageDu: 'Die Event-Anmeldung wurde erfolgreich übermittelt. Eine Bestätigung wurde entweder via E-Mail oder via SMS versandt. <br><br>Wenn Du aus irgendwelchen Gründen nicht am Event teilnehmen kannst, bitten wir Dich, die Teilnahme via Stornierungslink im E-Mail oder im SMS abzusagen.',
      successMessageSie: 'Die Event-Anmeldung wurde erfolgreich übermittelt. Eine Bestätigung wurde entweder via E-Mail oder via SMS versandt. <br><br>Wenn Sie aus irgendwelchen Gründen nicht am Event teilnehmen können, bitten wir Sie, ihre Teilnahme via Stornierungslink im E-Mail oder im SMS abzusagen.',
      errorTitle: 'Ein Fehler ist aufgetreten.',
      errorMessage: 'Bei der Anmeldung ist ein Fehler aufgetreten. Bitte versuch es erneut.',
      bookNextEvent: 'Eine weitere Anmeldung durchführen',
      bookEventAgain: 'Zurück gehen'
    }
  },
  resources: {
    noResources: 'Der Anbieter hat noch keine Ressourcen erfasst.',
    step1: {
      title: 'Ressourcen',
      chooseResource: 'Ressourcen auswählen'
    },
    step2: {
      title: 'Zeitfenster',
      startDate: 'Anfangsdatum: ',
      endDate: 'Enddatum: ',
      startTime: 'Bitte wählen Sie Startzeit',
      endTime: 'Bitte wählen Sie Endzeit',
      resetCalendar: 'Neues Datum im Kalender eingeben',
      noFreeResources: 'Keine buchbaren Ressourcen für diesen Bereich gefunden',
      selectDate: 'Datum oder Zeitraum wählen'
    },
    step3: {
      title: 'Zusammenfassung',
      summary: 'Zusammenfassung',
      company: 'Firma',
      store: 'Filiale',
      employee: 'Ressource',
      bookedEvent: 'Dienstleistung',
      date: 'Datum',
      time: 'Zeit',
      price: 'Preis',
      finalPrice: 'Kosten',
      total: 'Gesamt',
      yourData: 'Ihre Daten',
      disclaimer: 'Bitte geben Sie Ihre Kontaktdaten ein. Alle mit einem * gekennzeichneten Felder sind Pflichtfelder.',
      prename: {
        label: 'Vorname',
        placeholder: 'Vorname',
        error: {
          minChars: 'Die Vorname muss mindestens {{ requiredLength }} Zeichen lang sein.',
          required: 'Bitte Vorname angeben.'
        }
      },
      lastname: {
        label: 'Nachname',
        placeholder: 'Nachname',
        error: {
          minChars: 'Die Nachname muss mindestens {{ requiredLength }} Zeichen lang sein.',
          required: 'Bitte Nachname angeben.'
        }
      },
      phone: {
        label: 'Mobiltelefon',
        placeholder: '+4179xxxxxxx',
        error: {
          pattern: 'Die Telefonnummer darf nur Zahlen enthalten.',
          minChars: 'Die Telefonnummer muss mindestens {{ requiredLength }} Zeichen beinhalten.',
          required: 'Bitte eine Telefonnummer eingeben.'
        }
      },
      eMail: {
        label: 'E-Mail',
        error: {
          pattern: 'Bitte eine gültige E-Mail Adresse angeben.',
          minChars: 'Die E-Mail Adresse muss mindestens {{ requiredLength }} Zeichen lang sein.',
          required: 'Bitte eine E-Mail Adresse angeben.'
        }
      },
      message: {
        label: 'Nachricht',
        placeholder: ''
      },
      customFields: {
        defaultOption: 'Bitte eine Option auswählen',
        error: {
          minChars: 'Dieses Feld benötigt mindestens {{ requiredLength }} Zeichen.',
          required: 'Dieses Feld wird zwingend benötigt.'
        }
      },
      cart: {
        emptyCartWarning: 'Zurzeit gibt es keine Event im Warenkorb.'
      },
      From: 'Von',
      To: 'Zu'
    },
    finalpage: {
      successTitle: 'Die Ressource wurde erfolgreich gebucht.',
      successMessageDU: 'Wenn {{ courtesyForm }} den Termin nicht einhalten kannst, bitten wir Dich, diesen mindestens {{ leadTime }} Stunden vorher abzusagen.',
      successMessageSIE: 'Wenn {{ courtesyForm }} den Termin nicht einhalten können, bitten wir Sie, diesen mindestens {{ leadTime }} Stunden vorher abzusagen.',
      errorTitle: 'Ein Fehler ist aufgetreten.',
      errorMessage: 'Das Ressourcen konnte leider nicht gebucht werden. Bitte versuch es erneut.',
      bookNextEvent: 'Ein weiteres Ressourcen buchen',
      bookEventAgain: 'Zurück gehen'
    }
  },
  customer: {
    prename: {
      label: 'Vorname',
      placeholder: 'Vorname',
      error: {
        minChars: 'Der Vorname muss mindestens {{ requiredLength }} Zeichen enthalten.',
        required: 'Bitte Vorname angeben.'
      }
    },
    lastname: {
      label: 'Nachname',
      placeholder: 'Nachname',
      error: {
        minChars: 'Der Nachname muss mindestens {{ requiredLength }} Zeichen enthalten.',
        required: 'Bitte Nachname angeben.'
      }
    },
    phone: {
      label: 'Telefonnummer',
      placeholder: '+4179xxxxxxx',
      error: {
        pattern: 'Die Telefonnummer darf nur Zahlen enthalten.',
        minChars: 'Die Telefonnummer muss mindestens {{ requiredLength }} Zeichen enthalten.',
        required: 'Bitte Telefonnummer angeben.',
        invalid: 'Bitte geben Sie eine gültige Telefonnummer ein.'

      }
    },
    eMail: {
      label: 'E-Mail',
      error: {
        pattern: 'Bitte eine gültige E-Mail Adresse angeben.',
        minChars: 'Die E-Mail Adresse muss mindestens {{ requiredLength }} Zeichen enthalten.',
        required: 'Bitte E-Mail Adresse angeben.'
      }
    },
    street: {
      label: 'Strasse / Nr.',
      placeholder: 'Str. / Nr.',
      error: {
        minChars: 'Der Strassenname muss mindestens {{ requiredLength }} Zeichen enthalten.',
        required: 'Bitte Strasse angeben.'
      }
    },
    zip: {
      label: 'PLZ',
      placeholder: 'PLZ',
      error: {
        minChars: 'Die Postleitzahl muss mindestens {{ requiredLength }} Zeichen lang sein.',
        required: 'Bitte Postleitzahl angeben.',
        pattern: "Bitte geben Sie eine gültige Postleitzahl ein.",
      }
    },
    city: {
      label: 'Ort',
      placeholder: 'Ort',
      error: {
        minChars: 'Der Ort muss mindestens {{ requiredLength }} Zeichen lang sein.',
        required: 'Bitte Ort angeben.'
      }
    },
    message: {
      label: 'Nachricht',
      placeholder: ''
    },
    customFields: {
      defaultOption: 'Bitte eine Option auswählen',
      error: {
        minChars: 'Dieses Feld benötigt mindestens {{ requiredLength }} Zeichen.',
        required: 'Dieses Feld wird zwingend benötigt.'
      }
    },
    captcha: {
      required: 'Dieses Feld wird zwingend benötigt.',
      invalid: 'Bitte gültiges Captcha eingeben.',
      valid: 'Captcha erfolgreich abgeglichen.',
      placeholder: 'Bitte oben gezeigten Text einfügen.'
    }
  },
  stripe: {
    stripe_title: 'Bezahle deinen nächsten Besuch online',
    submit_payment: 'Zahlung ausführen',
    stripe_label: 'Kreditkarte oder Debitkarte',
    online_payment_optional: 'In diesem Schritt hast du die Möglichkeit, deinen nächsten Besuch bereits jetzt zu begleichen. Für dich fallen dabei keine zusätzlichen Gebühren an. Die Bezahlung läuft über einen sicheren Provider (Stripe) ab. Wir speichern keine Kreditkarten-Informationen auf unseren Servern.',
    online_payment_mandatory: 'Um die Buchung abzuschliessen, bitten wir dich, deinen nächsten Besuch nun zu begleichen. Für dich fallen dabei keine zusätzlichen Gebühren an. Die Bezahlung läuft über einen sicheren Provider (Stripe) ab. Wir speichern keine Kreditkarten-Informationen auf unseren Servern.'
  },
  alert_messages: {
    select_day_in_calendar_msg: "Einen spezifischen Tag im Kalender auswählen,  um mögliche Terminvorschläge zu erhalten.",
    no_events_in_month: "Im gewählten Monat gibt es leider keine freien Zeitfenster mehr.",
    no_events: "Dieses Event hat leider keine buchbaren Zeitfenster in der Zukunft. Es wird deshalb kein Kalender angezeigt um ein Datum auszuwählen.",
    waitinglist_enabled_message: "Einige Zeitfenster sind bereits ausgebucht. Eine Eintragung auf der Warteliste ist jedoch möglich. Sobald eine Teilnahme storniert wird, rückt der nächste Wartelistenplatz nach und bekommt eine Teilnahmebestätigung.",
    waitinglist_booking_message: "Vielen Dank! Wir haben deine Buchung erfolgreich entgegengenommen. Da du bei mindestens einem Event nun auf der Warteliste bist, bekommst du von uns erst eine Teilnahmebestätigung, wenn ein anderer Teilnehmer nicht am Event teilnehmen kann. In diesem Fall wirst du von uns per E-Mail benachrichtigt. Ohne eine offizielle Teilnahmebestätigung von unserer Seite aus kannst du leider aus Platzgründen nicht am Event teilnehmen.",
    window_is_fully_booked: "Dieses Zeitfenster ist ausgebucht (inkl. Warteliste).",
    waitinglist_is_available: "Dieses Zeitfenster ist ausgebucht, hat aber noch <b>{{ number }}</b> freie Plätze auf der Warteliste.",
    no_bookable_dates_message: "Leider gibt es im Monat {{ month }} keine buchbaren Tage mehr. Sollen wir zukünftige Monate nach freien Terminen durchforsten?",
    no_slots_dates_message: "Leider gibt es im Monat {{ month }} keine buchbaren Zeitfenster mehr. Sollen wir zukünftige Monate nach freien Zeitfenster durchforsten?",
    no_slots_dates_reached_message: "Leider gibt es im Monat {{ month }} keine buchbaren Zeitfenster mehr. Der maximale Buchungszeitraum wurde erreicht.",
    no_slots_years_reached_message: "Leider gibt es im Jahr {{ year }} keine buchbaren Zeitfenster mehr. Die maximale Buchungsdauer wurde erreicht.",
    no_bookable_dates_reached_message: "Leider gibt es im Monat {{ month }} keine buchbaren Termine mehr. Der maximale Buchungszeitraum wurde erreicht.",
    xss_error_message: "Dieser Text beinhaltet nicht unterstützte HTML-Tags.",
    slot_already_reserved_by_other_error: "In der Zwischenzeit wurde der Slot, den du buchen wolltest, von jemand anderem reserviert. Bitte wähle einen anderen Slot und versuche es später erneut.",
    failed_to_reserve_slot_error: "Etwas ist schiefgelaufen, während wir den Slot für dich reservieren wollten. Bitte versuche es später noch einmal!",
    reserved_by_someone: "Von jemand anderem reserviert",
  },
  coupon_credit_card_info: {
    redeem_coupon_1: 'Coupon einlösen',
    apply_coupon: 'Gutschein anwenden',
    coupon: 'Gutschein',
    credit_or_debit_card: 'Kredit- oder Debitkarte',
    invalid_coupon: 'Ungültiger Coupon',
    applied_successfully: 'Erfolgreich angewendet'
  },
  tax_info: {
    taxes: '(inkl. {{percentage}}% MWST)'
  },
  new_alert_component: {
    x_day_ago: 'vor x Tag',
    x_days_ago: 'vor x Tagen',
    x_minute_ago: 'vor x Minute',
    x_minutes_ago: 'vor x Minuten',
    x_hour_ago: 'vor x Stunde',
    x_hours_ago: 'vor x Stunden',
    x_second_ago: 'vor einer Sekunde',
    x_seconds_ago: 'vor x Sekunden',
    x_week_ago: 'vor einer Woche',
    x_weeks_ago: 'vor x Wochen',
    x_month_ago: 'vor x Monat',
    x_months_ago: 'vor x Monaten',
    x_year_ago: 'vor einem Jahr',
    x_years_ago: 'vor x Jahren'
  },
  summary_page_translations: {
    paypal_warning_msg: 'Bevor eine Buchung möglich ist, muss der offene Betrag mit PayPal bezahlt werden.',
    paypal: 'PayPal',
    saferpay_payment_overlay: 'Dieser Bildschirm wird automatisch ausgeblendet, sobald die Zahlung abgeschlossen oder abgebrochen wurde.<br/>Sollte der Zahlungsbildschirm versehentlich geschlossen worden sein, tippen Sie bitte auf die Schaltfläche \'Schliessen\', um ihn auszublenden und die Zahlung erneut einzuleiten.',
    or: 'oder',
    browser: 'Durchsuchen',
    choose_file: 'Datei wählen',
    clear: 'Zurücksetzen',
    meeting_type: 'Wo soll der Termin stattfinden?',
    email: 'E-Mail',
    sms: 'SMS',
    both_sms_email: 'E-Mail und SMS',
    notification_type: 'Benachrichtigungsart',
    payment_title: 'Bezahlung',
    payment_subtitle: 'Entscheide dich, wie du deine Dienstleitungen bezahlen möchtest',
    payment_subtitle_sie: 'Entscheiden Sie sich, wie sie ihre Dienstleistungen bezahlen möchten',
    summary_title: 'Zusammenfassung',
    summary_subtitle: 'Bitte Buchung überprüfen und absenden',
    summary_subtitle_sie: 'Prüfen Sie ihre Bestellung und senden Sie sie ab',
    payment_type: 'Zahlungsart',
    creditcard: 'Kreditkarte',
    invoice: 'Rechnung',
    lexoffice: 'Rechnung (LexOffice)',
    store: 'Vor Ort',
    apply_coupon: 'Ich möchte einen Rabatt-Code anwenden',
    choose_payment_method: 'Zahlungsmethode wählen',
    tax: 'inkl. {{ rate }}% MWST',
    subtotal: 'Zwischensumme',
    personal_form_title: 'Persönliche Daten',
    personal_form_subtitle: 'Bitte persönliche Informationen mitteilen',
    personal_form_subtitle_sie: 'Erzählen Sie uns etwas über sich',
    inclusive: 'inklusive',
    paymentMandatory: 'Der Anbieter hat definiert, dass die Dienstleistung vorgängig online bezahlt werden muss. Bitte wähle deshalb eine Zahlungsart aus.',
    file_upload: 'Datei-Upload',
    file_upload_helper_text: 'Mehrere Dateien können angehängt werden',
    file_was_uploaded: '"{{name}}" wurde erfolgreich hochgeladen',
    max_file_size_5mb: 'Die hochgeladene Datei ist zu gross (maximal 5MB sind erlaubt).',
    malware_found: 'Beim Virusscan der Datei ist ein Problem aufgetreten (Access denied). Bitte kontaktiere den Calenso Support!',
    multiple_file_upload_helper_text: 'Es können maximal 5 Dateien hochgeladen werden. Die hochzuladende Datei darf nicht grösser als 5 MB gross sein.',
    single_file_upload_helper_text: '1 Datei kann hochgeladen werden. Die hochzuladende Datei darf nicht grösser als 5 MB gross sein.',
    file_uploading: 'Der Datei-Upload ist noch im Gange. Bitte warte, bis die Aktion beendet ist und versuche dann erneut zu buchen.',
    maximum_x_files: 'Es können maximal {{fileLength}} Dateien hochgeladen werden.',
    document_invalid_file_type: "Ungültiger Dateityp. Nur PDF- und Excel-Dateien sind erlaubt.",
    image_invalid_file_type: "Ungültiger Dateityp. Bitte lade eine gültige Bilddatei hoch.",
    request_sms_code: 'Code anfordern',
    mobile_number_must_be_verified: 'Die Nummer muss für die Buchung verifiziert werden.',
    verification_code: 'Verifizierungscode',
    sms_code_verification_failed_msg: 'Der eingefügte Code stimmt leider nicht mit dem Code überein, welchen wir per SMS versendet haben.',
    check: 'Prüfen',
    phone_number_verified_successfully: 'Die Nummer wurde erfolgreich verifiziert.',
    resend_sms_helper_msg_1: 'Ich habe keinen Code erhalten.',
    resend_sms_helper_msg_2: 'Code nochmals anfordern.',
    resend_sms_successfully: 'SMS-Code erfolgreich erneut gesendet',
    exceeded_sms_verification_limit_msg: 'Diese Nummer hat die maximale Anzahl an Verifizierungscodes überschritten. Bitte den Calenso-Support kontaktieren (<a href="mailto:support@calenso.com">support@calenso.com</a>).',
    salutation: 'Anrede',
    mr: 'Herr',
    ms: 'Frau',
    other: 'andere',
    select_payment_method: 'Bitte zahlen Sie zuerst den offenen Betrag mit PayPal, bevor Sie die Buchung abschicken können.',
    accept_data_policy: 'Ich willige den <a href="https://calenso.com/agb/" target="_blank">AGB von Calenso</a> & <a href="https://calenso.com/datenschutz/" target="_blank">Datenschutzbestimmungen</a> zur Verwendung meiner Daten im Zusammenhang mit der Terminbuchung ein und habe diese gelesen.',
    saferpay: 'Saferpay',
    saferpay_pay: 'Zum Bezahlen mit Saferpay tippen',
    payment_success_title: 'Erfolgreiche Zahlung',
    payment_success_message: 'Der offene Betrag wurde erfolgreich mit {{ paymentMethod }} bezahlt. Der Termin kann nun gebucht werden. Kommt es zu keiner Buchung, wird kein Betrag belastet.',
    title: 'Titel',
    country: 'Land',
    title_aria_describedby: "Besonderer Titel, der Dr., Dr. med., Prof. unterstützt",
    salutation_aria_describedby: "Anredeform, die Herr, Frau und andere unterstützt",
    prename_aria_describedby: "Vorname oder Rufname",
    lastname_aria_describedby: "Nachname",
    notification_type_aria_describedby: "Im Buchungsprozess können Sie festlegen, wie Sie über die Buchung benachrichtigt werden möchten.",
    email_describedby: "E-Mail",
    phoneNumber_aria_describedby: "Telefonnummer",
    smsPhoneCode_aria_describedby: "OTP zur Verifizierung der Telefonnummer",
    mobileNumber_aria_describedby: "Handynummer",
    smsMobileCode_aria_describedby: "OTP zur Verifizierung der Mobilfunknummer",
    street_aria_describedby: "Adresse",
    zip_aria_describedby: "Postleitzahl",
    city_aria_describedby: "Stadt",
    country_aria_describedby: "Land",
    comment_aria_describedby: "Zusätzliche Kommentare, wenn Sie welche haben",
    internal_comment_aria_describedby: "Interne Anmerkung (nur für interne Zwecke)",
    company_aria_describedby: "Firmenname",
    captcha_aria_describedby: "Captcha"
  },
  buttons: {
    yes_search: 'Ja, suchen.',
    remove: 'Entfernen'
  },
  add: 'Hinzufügen',
  compact: {
    no_service: 'Derzeit können keine Termine gebucht werden, da keine Dienstleistung übergeben wurde.',
    navigation: {
      date: 'Datum',
      date_slots: 'Datum and Zeitfenster',
      personal_info: 'Persönliche Daten'
    }
  }
};
