export const it_CH = {
  customer_type: 'Tipo di cliente',
  manage_appointment_now: 'Gestisci appuntamento ora',
  maintenance_page_title: 'Calenso è stato migliorato per te in questo momento',
  maintenance_page_subtitle: 'Calenso è attualmente in fase di aggiornamento all\'ultima versione. Per favore, siate pazienti e tornate a controllare tra qualche minuto.',
  maintenance_page_button: 'Alla pagina di stato',
  load_more: 'Carica di più',
  show_more: 'Mostra di più',
  internal_label: 'Interno',
  check_availability_for_anyone: 'Verifica la disponibilità di tutte le risorse',
  store_not_found: 'Nessuna filiale trovata',
  appointment: 'Appuntamenti',
  event: 'Appuntamenti di gruppo',
  resource: 'Risorse',
  morgen: 'Domani',
  vormittag: 'Mattina',
  mittag: 'Mezzogiorno',
  nachmittag: 'Pomeriggio',
  abend: 'Sera',
  nacht: 'Notte',
  free: 'gratuito',
  hoursShort: '',
  minutesShort: 'min',
  mondayLong: 'Lunedì',
  day1: 'lun',
  tuesdayLong: 'Martedì',
  day2: 'mar',
  wednesdayLong: 'Mercoledì',
  day3: 'mer',
  thursdayLong: 'Giovedì',
  day4: 'gio',
  fridayLong: 'Venerdì',
  day5: 'ven',
  saturdayLong: 'Sabato',
  day6: 'sab',
  sundayLong: 'Domenica',
  day0: 'dom',
  Januar: 'Gennaio',
  Februar: 'Febbraio',
  März: 'Marzo',
  April: 'Aprile',
  Mai: 'Maggio',
  Juni: 'Giugno',
  Juli: 'Luglio',
  August: 'Agosto',
  September: 'Settembre',
  Oktober: 'Ottobre',
  November: 'Novembre',
  Dezember: 'Dicembre',
  "shortMonths": {
    "Januar": "Gen",
    "Februar": "Feb",
    "März": "Mar",
    "April": "Apr",
    "Mai": "Mag",
    "Juni": "Giu",
    "Juli": "Lug",
    "August": "Ago",
    "September": "Set",
    "Oktober": "Ott",
    "November": "Nov",
    "Dezember": "Dic"
  },
  du: 'tu',
  sie: 'lei',
  havingFormDu: 'hai',
  havingFormSie: 'ha',
  yes: "Sì",
  no: "No",
  confirm: {
    title: 'confermare la prenotazione',
    confirmationText: 'Hai {{ cartLength }} appuntamenti nella tua casella di posta. Vuoi riservare tutti questi appuntamenti in modo vincolante?',
    confirmationTextSie: 'Ha {{ cartLength }} appuntamenti nella sua casella. Vuole riservare tutti questi appuntamenti in modo vincolante?',
    ok: 'Riservare',
    cancel: 'Terminare'
  },
  internal: {
    search_customer: 'Ricerca di clienti esistenti e modulo precompilato',
    selectPlaceholder: 'Cercare il cliente',
    customerDetails: 'Dettagli del cliente',
    noCustomer: 'Non vi sono clienti configurati',
    create_customer: 'Creare clienti'
  },
  common: {
    report_bug: 'Segnala un bug',
    subscription_error_title: 'Nessun abbonamento!',
    subscription_error_desc: 'Spiacenti, non è stato possibile trovare un abbonamento. Si prega di contattare l\'assistenza Calenso.',
    next: 'Avanti',
    previous: 'Indietro',
    reset: 'Inviare il formulario',
    resetSuccess: 'Il software di prenotazione è stato resettato con successo. I dati salvati temporaneamente non sono più disponibili.',
    loading: 'Loading ...',
    durationHour: ' h',
    durationMinute: ' min',
    clock: 'ore',
    disclaimer: 'Questo servizio è fornito da <a href=\'https://www.calenso.com\' class=\'cal-link\' target=\'_blank\'>Calenso</a>.',
    book: 'Riservare',
    choseBookType: 'Che cosa vuole prenotare??',
    company: 'Impresa',
    prename: 'Nome',
    lastname: 'Cognome',
    email: 'E-mail',
    phone: 'Telefono',
    address: 'indirizzo',
    createAccount: 'Allestire un conto clienti (conto utente con tutte le iscrizioni)',
    noDateError: 'L\'appuntamento desiderato è stato prenotato da qualcun altro, tornare indietro e selezionare un nuovo appuntament',
    devNotice: 'Il widget di prenotazione è connesso al sistema di back-end di DEV. Stai attento!',
    store_access_warning: 'L\'accesso alla memoria del browser è limitato a causa delle tue impostazioni sulla privacy.Potrebbero non essere disponibili tutte le funzioni del widget di prenotazione.',
    save: "Salva",
    cancel: "Annulla",
    additionalParticipantsUpTo: "Puoi aggiungere fino a {{ number }} ospiti",
    additionalGuest: 'Partecipanti supplementari',
    addAdditionalGuest: 'Aggiungere partecipanti',
    additionalGuestCartLabel: '{{ number }} ospite aggiuntivo',
    additionalGuestsCartLabel: '{{ number }} ospiti aggiuntivi',
    widget_disabled: 'Il widget di prenotazione è attualmente disattivato',
    widget_disabled_description: 'Il widget di prenotazione è temporaneamente disabilitato. Si prega di contattare il supporto Calenso (Error-Code: PMT-01).',
    no_workers_widget_disabled_description: 'Nessun appuntamento può essere prenotato al momento perché nessuna risorsa è attivata.',
    workers_not_available: 'Nessun servizio prenotabile trovato',
    workers_not_available_description: 'Non è stato registrato alcun servizio prenotabile. Si prega di assicurarsi che almeno un servizio sia disponibile e che sia assegnato ad una risorsa prenotabile (capacità). L\'inserimento di una categoria di servizi non è sufficiente per essere prenotabile.',
    price_from_label: 'da',
    close: 'Chiudere',
    configuration_error: 'Errore di configurazione',
    resource_is_not_bookable: 'La risorsa selezionata non è prenotabile.',
    verification_token_missing: 'Il widget di prenotazione viene utilizzato in modalità interna e richiede pertanto un token di verifica. Contattare l’assistenza Calenso.',
    invalid_verification_code: 'Toegang tot de widget in interne modus vereist een geldige token, zorg ervoor dat de token geldig is en probeer het opnieuw.',
    internal_disabled: 'Il widget di prenotazione interno è disattivato.',
    evnet_disabled_error: "L'evento richiesto è disabilitato dal partner. Contatta il tuo amministratore.",
    conference_invalid_link: "Sembra che il link della conferenza fornito non sia valido. Per favore, controlla il link e riprova.",
    conference_expired_link: "Ci dispiace, ma questo link alla conferenza è scaduto. Per favore, contatta l'organizzatore per ulteriori assistenza.",
    conference_not_belonging: "Ops, sembra che questa conferenza sia collegata a un altro partner.",
    conference_booked: "Ops, sembra che questo link per la conferenza sia già stato utilizzato e prenotato. Se hai domande o hai bisogno di assistenza, sentiti libero di contattare gli organizzatori.",
    conference_invalid_configurations: "La conferenza non è disponibile a causa di configurazioni interne non valide. Per ulteriore assistenza, contatta l'organizzatore.",
    location: "Posizione"
  },
  appointments: {
    step1: {
      distance_from_store: '{{ distance }} km di distanza',
      search_store_postcode_helper_text: 'Inserire il codice postale per cercare la filiale più vicina',
      search_services_helper_text: 'Inserire un termine di ricerca per la ricerca di servizi',
      title: 'Scelta',
      chooseStore: 'Scegliere la filiale',
      chooseService: 'Scegliere il servizio',
      nextButton: 'Avanti',
      search_store_by_postcode_placeholder: 'Inserire il codice postale per cercare la filiale corretta',
      create_lead: 'Opportunità MH',
      expert_cap_button_label: 'Assegnare la VVB all\'esperto',
      appointment_booking_not_available_for_zipcode: 'Purtroppo non è possibile prenotare un appuntamento per questo codice postale. Creare un nuovo cliente in modo che un CAP possa contattarlo per telefono.'
    },
    step2: {
      title: 'Risorsa',
      chooseEmployee: 'Scegliere il risorsa',
      name: 'Nome',
      function: 'Funzione',
      noSpecificWorker: 'Indifferente',
      noSpecificFunction: 'Tutte le sezioni',
      nextButton: 'Scegliere la data/l\'ora'
    },
    step3: {
      title: 'Data',
      chooseDate: 'Scegliere la data desiderata',
      morning: 'Domani',
      forenoon: 'Mattina',
      noon: 'Mezzogiorno',
      afternoon: 'Pomeriggio',
      evening: 'Sera',
      night: 'Notte',
      infoMsg: 'Vogliate osservare che sono possibili più appuntamenti contemporaneamente. Scegliete semplicemente l\'appuntamento desiderato via Checkbox. Gli appuntamenti vengono inviati nella vostra casella di posta.',
      appointment: 'Appuntamento',
      employee: 'Risorsa',
      proposals: 'Proposta di appuntamento',
      nextButton: 'Avanti',
      noFreeAppointments: 'In questa giornata purtroppo non vi sono più appuntamenti liberi!',
      cartNotSupported: 'Questo fornitore supporta una sola registrazione di slot per ogni prenotazione.',
      cartSupported: 'Questo provider supporta registrazioni multiple per ogni prenotazione.',
      workerSelectLable: "Seleziona Consulente Personale:",
      workerSelectLinkPrefix: "Non hai trovato un appuntamento con il tuo consulente?",
      workerSelectShowAllLinkPostfix: "Mostra tutti i consulenti",
      workerSelectHideAllLinkPostfix: "Nascondi tutti i consulenti"
    },
    step4: {
      comment_helper_text: 'Massimo 2000 caratteri',
      title: 'Conclusione',
      summary: 'Riassunto',
      company: 'Impresa',
      store: 'Filiale',
      employee: 'Risorsa',
      bookedServices: 'Servizio',
      duration: 'Durata',
      price: 'Prezzo',
      finalPrice: 'Costi',
      total: 'Totale',
      yourData: 'I suoi dati',
      disclaimer: 'Inserisca i suoi dati di contatto. Tutti i campi contraddistinti da * sono obbligatori.',
      prename: {
        label: 'Nome',
        placeholder: 'Nome',
        error: {
          minChars: 'Il nome deve contenere almeno {{ requiredLength }} caratteri.',
          required: 'Inserire un nome.'
        }
      },
      lastname: {
        label: 'Cognome',
        placeholder: 'Cognome',
        error: {
          minChars: 'Il cognome deve contenere almeno {{ requiredLength }} caratteri.',
          required: 'Inserire un cognome.'
        }
      },
      phone: {
        label: 'Telefono cellulare',
        placeholder: '+4179xxxxxxx',
        error: {
          pattern: 'Il numero di telefono può contenere solo cifre.',
          minChars: 'Il numero di telefono deve contenere almeno {{ requiredLength }} cifre.',
          required: 'Inserire un numero di telefono.'
        }
      },
      eMail: {
        label: 'E-mail',
        error: {
          pattern: 'Inserire un\'indirizzo e-mail valido.',
          minChars: 'L\'indirizzo e-mail deve contenere almeno {{ requiredLength }} caratteri.',
          required: 'Inserire un\'indirizzo e-mail.'
        }
      },
      message: {
        label: 'Messaggio',
        placeholder: ''
      },
      internal_comment: {
        label: 'Commento interno',
        placeholder: 'Aggiungi commenti per uso interno',
        comment_helper_text: 'Massimo 5000 caratteri'
      },
      company_name: {
        label: 'Nome dell\'azienda',
        error: {
          minChars: 'Questo campo necessita di almeno {{ requiredLength }} caratteri.'
        }
      },
      customFields: {
        defaultOption: 'Scegliere un\'opzione',
        error: {
          maxChars: 'Questo campo consente un massimo di {{ requiredLength }} caratteri.',
          minChars: 'Questo campo necessita di almeno {{ requiredLength }} caratteri.',
          required: 'Questo campo è obbligatorio.',
          questionisRequired: "{{ text }} è obbligatorio",
          checkboxisRequired: "Questa casella di controllo deve essere selezionata per continuare."
        }
      },
      cart: {
        emptyCartWarning: 'Al momento non vi sono appuntamenti nella casella di posta.',
        removeIcon: 'Eliminare l\'appuntamento dalla casella di posta.',
        waiting_list: 'Lista d’attesa'
      },
      numberSelect: {
        increase: 'Aumentare valore',
        decrease: 'Diminuire valore'
      }
    },
    finalpage: {
      successTitle: 'Riservazione effettuata con successo',
      successMessageDu: 'La prenotazione dell\'appuntamento è stata trasmessa con successo. Una conferma è stata inviata via e-mail o via SMS. <br> <br> Se per qualsiasi motivo non potete partecipare all\'evento, siete pregati di annullare la vostra partecipazione tramite il link di cancellazione presente nell\'e-mail o nel messaggio di testo.',
      successMessageSie: 'La prenotazione dell\'appuntamento è stata trasmessa con successo. Una conferma è stata inviata via e-mail o via SMS. <br> <br> Se per qualsiasi motivo non potete partecipare all\'evento, siete pregati di annullare la vostra partecipazione utilizzando il link di cancellazione contenuto nell\'e-mail o nel messaggio di testo.',
      errorTitle: 'Si è verificato un errore.',
      errorMessage: 'Purtroppo non è stato possibile riservare l\'appuntamento, si prega di riprovare.',
      errorMessageDu: "Siamo spiacenti, non è stato possibile prenotare l'appuntamento. Riprova.",
      errorMessageSie: "Spiacente, non è stato possibile prenotare l'appuntamento. Riprova.",
      bookNextAppointment: 'Riservare un altro appuntamento',
      bookAppointmentAgain: 'Tornare indietro'
    },
    error: {
      noworkers: 'Questo offerente purtroppo non ha stabilito nessun risorsa.'
    }
  },
  events: {
    list: "Liste",
    grid: "Griglia",
    noEvents: "L'offerente non ha inserito ancora nessun evento.",
    hidden_events_message: "Gli eventi di questo partner sono nascosti. Si prega di utilizzare il link diretto all'evento ricevuto dal partner per registrarsi.",
    filter_result_null: "Non ci sono risultati che corrispondono ai tuoi criteri.",
    step1: {
      title: "Eventi",
      moreInformation: "Maggiori informazioni",
      noFreeSlots: "Nessuna slot libera rimasta",
      noEvents: "Non è ancora stato creato alcun evento.",
      showDescription: "Mostrare l'intera descrizione",
      hideDescription: "Nascondere la descrizione",
      book: "Libro",
      noPlaceDefined: "Nessun luogo definito",
      store_filter: "Filtro del negozio",
      category_filter: "Filtro della categoria",
      all_categories: "Tutti"
    },
    step2: {
      title: 'Slot',
      slots: 'Slot',
      chooseDate: 'Scegliere la data desiderata',
      booked: 'Riservato',
      capacity: 'Disponibile',
      noFreeDates: 'Non vi sono eventi gratuiti per questo partner',
      bookedOut: 'Questo slot è già prenotato',
      cartNotSupported: 'Questo fornitore supporta una sola registrazione di slot per ogni prenotazione.',
      cartSupported: 'Questo provider supporta registrazioni multiple per ogni prenotazione.',
      alreadyBookedByUser: 'Hai già prenotato questo slot. In caso di problemi, ripristinare l\'applicazione.',
      waitinglist: 'lista d\'attesa: {{ capacity }} libero',
      fully_booked: "Pieno",
      regular_count_singular: '<b>1</b> spazio libero',
      regular_count_plural: '<b>{{ number }}</b> posti liberi',
      waiting_count_singular: "1 posto libero in lista d'attesa",
      waiting_count_plural: "{{ number }} posti liberi in lista d'attesa",
      free_seats: 'Posti liberi',
      free_waiting_seats: 'Posti gratuiti in lista d\'attesa',
      multiday_group_appointment: "Questo è un appuntamento di gruppo su più giorni"
    },
    step3: {
      title: 'Riassunto',
      summary: 'Riassunto',
      company: 'Impresa',
      store: 'Filiale',
      employee: 'Risorsa',
      bookedEvent: 'Servizio',
      date: 'Data',
      time: 'Orario',
      price: 'Prezzo',
      finalPrice: 'Costi',
      total: 'Totale',
      yourData: 'I suoi dati',
      disclaimer: 'Inserisca i suoi dati di contatto. Tutti i campi contrassegnati da * sono obbligatori.',
      cart: {
        emptyCartWarning: 'Al momento non vi sono eventi nella casella di posta.',
        waiting_list: 'Lista d’attesa'
      }
    },
    finalpage: {
      successTitle: 'L\'iscrizione è avvenuta con successo.',
      successMessageDu: 'La registrazione dell\'evento è stata inviata con successo. Una conferma è stata inviata via e-mail o via SMS. <br> <br> Se per qualsiasi motivo non potete partecipare all\'evento, siete pregati di annullare la vostra partecipazione tramite il link di cancellazione presente nell\'e-mail o nel messaggio di testo.',
      successMessageSie: 'La registrazione dell\'evento è stata inviata con successo. Una conferma è stata inviata via e-mail o via SMS. <br> <br> Se per qualsiasi motivo non potete partecipare all\'evento, siete pregati di annullare la vostra partecipazione utilizzando il link di cancellazione contenuto nell\'e-mail o nel messaggio di testo.',
      errorTitle: 'Si è verificato un errore.',
      errorMessage: 'Si è verificato un errore al momento della registrazione. Riprovare.',
      bookNextEvent: 'Registrarsi nuovamente',
      bookEventAgain: 'Indietro'
    }
  },
  resources: {
    noResources: 'L\'offerente non ha ancora inserito alcuna risorsa.',
    step1: {
      title: 'Risorse',
      chooseResource: 'Scegliere le risorse'
    },
    step2: {
      title: 'Slot',
      startDate: 'Data d\'inizio: ',
      endDate: 'Data di termine: ',
      startTime: 'Scegliere un orario d\'inizio',
      endTime: 'Scegliere un orario di fine',
      resetCalendar: 'Inserire una nuova data nel calendario',
      noFreeResources: 'Non sono state trovate risorse prenotabili per questo settore',
      selectDate: 'Scegliere una data o un orario'
    },
    step3: {
      title: 'Riassunto',
      summary: 'Riassunto',
      company: 'Impresa',
      store: 'Filiale',
      employee: 'Risorsa',
      bookedEvent: 'Servizio',
      date: 'Data',
      time: 'Orario',
      price: 'Prezzo',
      finalPrice: 'Costi',
      total: 'Totale',
      yourData: 'I suoi dati',
      disclaimer: 'Inserisca i suoi dati di contatto. Tutti i campi contrassegnati da * sono campi obbligatori.',
      prename: {
        label: 'Nome',
        placeholder: 'Nome',
        error: {
          minChars: 'Il nome deve contenere almeno {{ requiredLength }} caratteri.',
          required: 'Inserire il nome.'
        }
      },
      lastname: {
        label: 'Cognome',
        placeholder: 'Cognome',
        error: {
          minChars: 'Il cognome deve contenere almeno {{ requiredLength }} caratteri.',
          required: 'Inserire il cognome.'
        }
      },
      phone: {
        label: 'Telefono cellulare',
        placeholder: '+4179xxxxxxx',
        error: {
          pattern: 'Il numero di telefono può contenere solo cifre.',
          minChars: 'Il numero di telefono deve contenere solo {{ requiredLength }} cifre.',
          required: 'Inserire un numero di telefono.'
        }
      },
      eMail: {
        label: 'E-mail',
        error: {
          pattern: 'Inserire un indirizzo e-mail valido.',
          minChars: 'L\'indirizzo e-mail deve contenere almeno {{ requiredLength }} caratteri.',
          required: 'Inserire un indirzzo e-mail.'
        }
      },
      message: {
        label: 'Messaggio',
        placeholder: ''
      },
      customFields: {
        defaultOption: 'Scegliere l\'opzione',
        error: {
          minChars: 'Questo campo richiede almeno {{ requiredLength }} caratteri.',
          required: 'Questo campo è obbligatorio.'
        }
      },
      cart: {
        emptyCartWarning: 'Attualmente non vi sono eventi nella casella di posta.'
      },
      From: 'Da',
      To: 'A'
    },
    finalpage: {
      successTitle: 'La riservazione della risorsa è andata a buon fine.',
      successMessageDU: 'La prenotazione dell\'appuntamento è stata trasmessa con successo. Una conferma è stata inviata tramite e-mail o SMS. <br> <br> Se per qualsiasi motivo non puoi partecipare all\'evento, ti chiediamo di annullare la partecipazione almeno {{leadTime}} ore in anticipo tramite il link di cancellazione nell\'e-mail o nell\'SMS.',
      successMessageSIE: 'La prenotazione dell\'appuntamento è stata trasmessa con successo. Una conferma è stata inviata tramite e-mail o SMS. <br> <br> Se per qualsiasi motivo non puoi partecipare all\'evento, ti chiediamo di annullare la partecipazione almeno {{leadTime}} ore in anticipo tramite il link di cancellazione nell\'e-mail o nell\'SMS.',
      errorTitle: 'Si è verificato un errore.',
      errorMessage: 'Non è stato purtroppo possibile riservare le risorse. Riprovare.',
      bookNextEvent: 'Riservare altre risorse',
      bookEventAgain: 'Indietro'
    }
  },
  customer: {
    prename: {
      label: 'Nome',
      placeholder: 'Nome',
      error: {
        minChars: 'Il nome deve contenere almeno {{ requiredLength }} caratteri.',
        required: 'Inserire il nome.'
      }
    },
    lastname: {
      label: 'Cognome',
      placeholder: 'Cognome',
      error: {
        minChars: 'Il cognome deve contenere almeno {{ requiredLength }} caratteri.',
        required: 'Inserire il cognome.'
      }
    },
    phone: {
      label: 'Numero di telefono',
      placeholder: '+4179xxxxxxx',
      error: {
        pattern: 'Il numero di telefono può contenere solo cifre.',
        minChars: 'Il numero di telefono deve contenere almeno {{ requiredLength }} cifre.',
        required: 'Inserire un numero di telefono.',
        invalid: 'Inserisci un numero di cellulare valido.'
      }
    },
    eMail: {
      label: 'E-mail',
      error: {
        pattern: 'Inserire un indirizzo e-mail valido.',
        minChars: 'L\'indirizzo e-mail deve contenere almeno {{ requiredLength }} caratteri.',
        required: 'Inserire un indirizzo e-mail.'
      }
    },
    street: {
      label: 'Via / N.',
      placeholder: 'Nome della via',
      error: {
        minChars: 'Il nome della via deve contenere almeno {{ requiredLength }} caratteri.',
        required: 'Inserire il nome della via.'
      }
    },
    zip: {
      label: 'NPA',
      placeholder: 'NPA',
      error: {
        minChars: 'Il numero postale di avviamento deve contenere almeno {{ requiredLength }} caratteri.',
        required: 'Inserire un numero postale di avviamento.',
        pattern: "Inserisci un codice postale valido, per favore.",
      }
    },
    city: {
      label: 'Luogo',
      placeholder: 'Luogo',
      error: {
        minChars: 'Il luogo deve contenere almeno {{ requiredLength }} caratteri.',
        required: 'Inserire un luogo.'
      }
    },
    message: {
      label: 'Messaggio',
      placeholder: ''
    },
    customFields: {
      defaultOption: 'Scegliere un\'opzione',
      error: {
        minChars: 'Questo campo richiede almeno {{ requiredLength }} caratteri.',
        required: 'Questo campo è obbligatorio.'
      }
    },
    captcha: {
      required: 'Questo campo è obbligatorio.',
      invalid: 'Inserire un captcha valido.',
      valid: 'Captcha abbinato con successo.',
      placeholder: 'Digitare il testo mostrato sopra'
    }
  },
  stripe: {
    stripe_title: 'Paga per la tua prossima visita online',
    submit_payment: 'Inviare pagamento',
    stripe_label: 'Carta di credito o di debito',
    online_payment_optional: 'In questo passaggio, hai l\'opportunità di sistemare la tua prossima visita in questo momento. Non ci sono costi aggiuntivi per te. Il pagamento viene effettuato tramite un fornitore sicuro (Stripe). Non memorizziamo le informazioni della carta di credito sui nostri server.',
    online_payment_mandatory: 'Per completare la prenotazione, ti chiediamo di pagare la tua prossima visita ora. Non ci sono costi aggiuntivi per te. Il pagamento viene effettuato tramite un fornitore sicuro (Stripe). Non memorizziamo le informazioni della carta di credito sui nostri server.'
  },
  alert_messages: {
    select_day_in_calendar_msg: "Seleziona un giorno specifico nel calendario per ottenere possibili date suggerite.",
    no_events_in_month: "Purtroppo non ci sono più fasce orarie libere nel mese selezionato.",
    no_events: "Sfortunatamente, questo evento non ha finestre prenotabili in futuro. Pertanto, non viene visualizzato alcun calendario per selezionare una data.",
    waitinglist_enabled_message: "Alcune fasce orarie sono già al completo. Tuttavia, è possibile iscriversi alla lista d'attesa. Non appena una partecipazione viene cancellata, il successivo posto in lista d'attesa si sposta e riceve una conferma di partecipazione.",
    waitinglist_booking_message: "Many Thanks! We have successfully accepted your booking. Since you are now on the waiting list for at least one event, you will only receive a confirmation of participation from us if another participant can not attend the event. In this case, you will be notified by e-mail. Unfortunately, due to lack of space, you will not be able to attend the event without an official confirmation from our site....",
    window_is_fully_booked: "Questa finestra temporale è completamente prenotata (inclusa lista d’attesa).",
    waitinglist_is_available: "Questa fascia oraria è al completo, ma ci sono ancora <b>{{ number }}</b> posti disponibili sulla lista d'attesa.",
    no_bookable_dates_message: "Purtroppo non ci sono più giorni prenotabili nel mese di {{ month }}. Dobbiamo cercare date gratuite nei prossimi mesi?",
    no_slots_dates_message: "Purtroppo non ci sono più fasce orarie prenotabili nel mese di {{ month }}. Dobbiamo cercare nei prossimi mesi le fasce orarie libere?",
    no_slots_dates_reached_message: "Purtroppo non ci sono più fasce orarie prenotabili nel mese di {{ month }}. Il periodo massimo di prenotazione è stato raggiunto.",
    no_slots_years_reached_message: "Purtroppo non ci sono più slot prenotabili nell'anno {{ year }}. È stato raggiunto il periodo massimo di prenotazione.",
    no_bookable_dates_reached_message: "Purtroppo non ci sono più date prenotabili nel mese di {{ month }}. Il periodo massimo di prenotazione è stato raggiunto.",
    xss_error_message: "Questo testo contiene tag HTML non supportati.",
    slot_already_reserved_by_other_error: "Nel frattempo, la fascia oraria che stavi cercando di prenotare è stata già riservata da qualcun altro. Scegli un'altra fascia oraria e riprova più tardi.",
    failed_to_reserve_slot_error: "Qualcosa è andato storto durante la prenotazione. Per favore, riprova più tardi!",
    reserved_by_someone: "Riservato da qualcun altro"
  },
  coupon_credit_card_info: {
    redeem_coupon_1: 'Riscattare il coupon',
    apply_coupon: 'Applicare Coupon',
    coupon: 'Coupon',
    credit_or_debit_card: 'Carta di credito o di debito',
    invalid_coupon: 'Coupon non valido',
    applied_successfully: 'Applicato con successo'
  },
  tax_info: {
    taxes: '(compreso il {{percentage}}% di IVA)'
  },
  new_alert_component: {
    x_day_ago: 'x giorno fa',
    x_days_ago: 'x giorni fa',
    x_minute_ago: 'x minuto fa',
    x_minutes_ago: 'x minuti fa',
    x_hour_ago: 'x ora fa',
    x_hours_ago: 'x ore fa',
    x_second_ago: 'x secondo fa',
    x_seconds_ago: 'x secondi fa',
    x_week_ago: 'x settimana fa',
    x_weeks_ago: 'x settimane fa',
    x_month_ago: 'x mese fa',
    x_months_ago: 'x mesi fa',
    x_year_ago: 'x anno fa',
    x_years_ago: 'x anni fa'
  },
  summary_page_translations: {
    paypal_warning_msg: 'Prima che una prenotazione sia possibile, l\'importo in sospeso deve essere pagato con PayPal.',
    paypal: 'PayPal',
    saferpay_payment_overlay: 'Questa schermata si nasconde automaticamente una volta che il pagamento è stato completato o annullato.<br/>Nel caso in cui la schermata di pagamento si chiudesse accidentalmente, si prega di toccare il pulsante di chiusura per nasconderla e reiniziare il pagamento.',
    or: 'o',
    browser: 'Sfogliare',
    choose_file: 'Selezionare il file',
    clear: 'Resettare',
    meeting_type: 'Dove deve avvenire l\'appuntamento?',
    email: 'E-mail',
    sms: ' SMS',
    both_sms_email: 'E-mail e SMS',
    notification_type: 'Tipo di notifica',
    payment_title: 'Pagamento',
    payment_subtitle: 'Decidete come volete pagare i vostri servizi',
    payment_subtitle_sie: 'Decidete come volete pagare i vostri servizi',
    summary_title: 'Sommario',
    summary_subtitle: 'Controlla il tuo ordine e spediscilo',
    summary_subtitle_sie: 'Controlla il tuo ordine e spediscilo',
    payment_type: 'Tipo di pagamento',
    creditcard: 'Carta di credito',
    invoice: 'Fattura',
    lexoffice: 'Fattura (LexOffice)',
    store: 'Sul sito',
    apply_coupon: 'Vorrei applicare un codice sconto',
    choose_payment_method: 'Scegliere il metodo di pagamento',
    tax: 'incl. {{ rate }}% IVA',
    subtotal: 'Subtotale',
    personal_form_title: 'Dati personali',
    personal_form_subtitle: 'Raccontaci qualcosa di te',
    personal_form_subtitle_sie: 'Raccontaci qualcosa di te',
    inclusive: 'inclusivo',
    paymentMandatory: 'Il fornitore ha stabilito che il servizio deve essere pagato in anticipo online. Pertanto, si prega di scegliere un metodo di pagamento.',
    file_upload: 'Caricamento file',
    file_upload_helper_text: 'È possibile allegare più file',
    file_was_uploaded: '"{{name}}" è stato caricato con successo',
    max_file_size_5mb: 'Il file caricato è troppo grande (sono ammessi al massimo 5MB).',
    malware_found: 'Si è verificato un problema durante la scansione del file per i virus (Accesso negato). Contatta il supporto di Calenso!',
    multiple_file_upload_helper_text: 'È possibile caricare un massimo di 5 file. Il file da caricare non deve essere più grande di 5 MB.',
    single_file_upload_helper_text: '1 file può essere caricato. Il file da caricare non deve essere più grande di 5 MB.',
    file_uploading: 'Il caricamento del file è ancora in corso. Per favore aspetta che l\'azione sia finita e poi prova a prenotare di nuovo.',
    maximum_x_files: 'È possibile caricare un massimo di {{fileLength}} file.',
    document_invalid_file_type: "Tipo di file non valido. Sono ammessi solo file PDF ed Excel.",
    image_invalid_file_type: "Tipo di file non valido, carica un file immagine valido.",
    request_sms_code: 'Richiesta codice',
    mobile_number_must_be_verified: 'Il numero deve essere verificato per la prenotazione.',
    verification_code: 'Codice di verifica',
    sms_code_verification_failed_msg: 'Purtroppo il codice inserito non corrisponde al codice che abbiamo inviato via SMS.',
    check: 'Controlla',
    phone_number_verified_successfully: 'Il numero è stato verificato con successo.',
    resend_sms_helper_msg_1: 'Non ho ricevuto il codice.',
    resend_sms_helper_msg_2: 'Richiedi nuovo codice.',
    resend_sms_successfully: 'Codice SMS reinviato con successo',
    exceeded_sms_verification_limit_msg: 'Questo numero ha superato il numero massimo di codici di verifica. Si prega di contattare il supporto Calenso (<a href="mailto:support@calenso.com">support@calenso.com</a>).',
    salutation: 'Saluto',
    mr: 'Signor',
    ms: 'Signora',
    other: 'altro',
    select_payment_method: 'Si prega di pagare l\'importo aperto utilizzando PayPal, prima di poter inviare la prenotazione.',
    accept_data_policy: 'Accetto i termini e le <a href="https://calenso.com/agb/" target="_blank">condizioni di Calenso</a> & <a href="https://calenso.com/datenschutz/" target="_blank">l\'informativa sulla privacy</a> per l\'utilizzo dei miei dati in relazione alla prenotazione dell\'appuntamento e li ho letti.',
    saferpay: 'Saferpay',
    saferpay_pay: 'Tocca per pagare utilizzando Saferpay',
    payment_success_title: 'Pagamento riuscito',
    payment_success_message: 'L\'importo aperto è stato pagato con successo con {{ paymentMethod }}. L\'appuntamento può ora essere prenotato. Se non c\'è prenotazione, non viene addebitato alcun importo.',
    title: 'Titolo',
    country: 'Paese',
    title_aria_describedby: "Titolo speciale che supporta Dott., Dott. med., Prof.",
    salutation_aria_describedby: "Forma di saluto che supporta Sig., Sig.ra e altri",
    prename_aria_describedby: "Nome o nome",
    lastname_aria_describedby: "Cognome",
    notification_type_aria_describedby: "Per definire nel processo di prenotazione come vuoi essere notificato sulla prenotazione.",
    email_describedby: "Email",
    phoneNumber_aria_describedby: "Numero di telefono",
    smsPhoneCode_aria_describedby: "OTP per verificare il numero di telefono",
    mobileNumber_aria_describedby: "Numero di cellulare",
    smsMobileCode_aria_describedby: "OTP per verificare il numero di cellulare",
    street_aria_describedby: "Indirizzo",
    zip_aria_describedby: "Codice postale",
    city_aria_describedby: "Città",
    country_aria_describedby: "Paese",
    comment_aria_describedby: "Commenti aggiuntivi se ne hai",
    internal_comment_aria_describedby: "Commento interno (solo per uso interno)",
    company_aria_describedby: "Nome dell'azienda",
    captcha_aria_describedby: "Captcha"
  },
  buttons: {
    yes_search: 'Si\', sto cercando.',
    remove: 'Rimuovi'
  },
  add: 'Inserisci',
  compact: {
    no_service: 'Al momento non è possibile prenotare appuntamenti perché non è stato trovato alcun servizio corrispondente.',
    navigation: {
      date: 'Data',
      date_slots: 'Data e fascia oraria',
      personal_info: 'Dati personali'
    }
  }
};
