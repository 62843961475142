@if (worker && !conferenceState?.uuid) {
  <div class="d-flex cbw-worker-card mb-4">
    <div class="avatar d-flex justify-content-center align-items-start">
      @if (worker.avatar !== null) {
        <img
          #image
          class="cbw-avatar"
          alt="Worker Avatar"
          src="{{ workerAvatarUrl }}{{ worker.uuid }}/thumb_{{ worker.avatar }}"
          (error)="image.src= baseUrl + '/img/sample-avatar.png'"
        />
      } @else {
        <img alt="Worker Avatar" class="cbw-avatar" src="{{ baseUrl }}/img/sample-avatar.png" />
      }
    </div>

    <div class="cbw-detail_description">
      <div class="cbw-title">
        @if (worker.is_resource === 0) {
          {{ worker.resource_name }}
        } @else if (worker.is_resource === 1) {
          @if (worker.is_multi_language === 1) {
            {{ worker | translation: 'booking_label': lang }}
          } @else if (worker.is_multi_language === 0) {
            {{ worker.resource_name }}
          }
        }
      </div>

      @if (worker.is_resource === 0 && worker.job_title?.trim()) {
        <div
          class="cbw-function"
          [innerHTML]="(worker | translation: 'job_title': lang) | trustHtml"
        ></div>
      } @else if (worker.is_resource !== 0 && worker.description?.trim()) {
        <div
          class="cbw-function"
          [innerHTML]="(worker | translation: 'description': lang) | trustHtml"
        ></div>
      }

      @if (partner?.widget_show_resource_store_name === 1) {
        <div class="cbw-worker-store-location">
          <span class="cbw-worker-store-location-name">{{ worker.store | translation: 'name' : lang }}</span>
          @if (worker.store.is_online === 0) {
            <span class="cbw-worker-store-location-separator">, </span>
            <span class="cbw-worker-store-location-address">
              @if (worker.store.street) {
                <span>{{ worker.store.street }}</span>
              }
              @if (worker.store.zip) {
                <span>,&nbsp;{{ worker.store.zip }}</span>
              }
              @if (worker.store.city) {
                <span>&nbsp;{{ worker.store.city }}</span>
              }
              @if (worker.store.country) {
                <span>,&nbsp;{{ worker.store.country | translation: 'name' : lang }}</span>
              }
            </span>
          }
        </div>
      }

      @if (worker?.attributes?.length) {
        @for (attribute of worker.attributes; track attribute.uuid) {
          @if (attribute.type === 'select') {
            <div class="attribute-container cbw-attribute-container">
              <div class="cbw-attribute">
                {{ attribute | translation: 'name': lang }}:
              </div>
              <div class="attribute-value-section">
                @for (value of attribute.attributes_values; track value.uuid; let last = $last) {
                  <div class="attribute-value-img">
                    @if (value.icon_url !== null) {
                      <img
                        #image
                        class="cbw-attribute-icon"
                        alt="{{ value.value }}"
                        src="{{ value.icon_url }}"
                        (error)="image.src= baseUrl + '/img/sample-avatar.png'"
                      />
                    }
                    <div class="cbw-attribute-value">
                      {{ value.value }}
                      @if (!last) { | }
                    </div>
                  </div>
                }
              </div>
            </div>
          } @else {
            <div class="cbw-attribute cbw-attribute-container">
              {{ attribute | translation: 'name': lang }}:
              @if (attribute.attributes_values?.length) {
                {{ +attribute.attributes_values[0].value === 1 ? ( 'yes' | translate ): ( 'no' | translate ) }}
              }
            </div>
          }
        }
      }
    </div>
  </div>
}

<!--Calendar subheader week days in shot form (Mo, Tu)-->
<ng-template #shortDays>
  <div class="cal-cell-row cal-header">
    @for (day of days | slice:0:7; track day) {
      <div
        class="cal-cell"
        [class.cal-past]="day.isPast"
        [class.cal-today]="day.isToday"
        [class.cal-future]="day.isFuture"
        [class.cal-weekend]="day.isWeekend"
        [ngClass]="day.cssClass"
      >
        {{ 'day' + day.date.getDay() | translate }}
      </div>
    }
  </div>
</ng-template>

<div class="btn-group d-flex calendar-header align-items-center">
  @if (!disablePrev) {
    <div
      class="btn w-100"
      mwlCalendarPreviousView
      view="month"
      [(viewDate)]="viewDate"
      (viewDateChange)="viewDateChanged(viewDate, false)"
    >
      <div class="prev-arrow-container">
        <span>
          <fa-icon class="icon cbw-prev-arrow-button d-flex" icon="chevron-left" />
        </span>
      </div>
    </div>
  } @else {
    <div class="btn w-100 disabled">
      <div class="prev-arrow-container">
        <span>
          <fa-icon class="icon cbw-prev-arrow-button d-flex" icon="chevron-left" />
        </span>
      </div>
    </div>
  }

  <div class="btn w-100 disabled">
    <div class="calendar-header-button-text text-overflow-center">
      <h3>{{ viewDate | date: 'MMMM' | translate }}</h3>
      <p>{{ viewDate | date: 'y' }}</p>
    </div>
  </div>

  @if (!disableNext) {
    <div
      class="btn w-100"
      mwlCalendarNextView
      view="month"
      [(viewDate)]="viewDate"
      (viewDateChange)="viewDateChanged(viewDate, true)"
    >
      <div class="next-arrow-container">
        <span>
          <fa-icon class="icon cbw-next-arrow-button d-flex" icon="chevron-right" />
        </span>
      </div>
    </div>
  } @else {
    <div class="btn w-100 disabled">
      <div class="next-arrow-container">
        <span>
          <fa-icon class="icon cbw-next-arrow-button d-flex" icon="chevron-right" />
        </span>
      </div>
    </div>
  }
</div>

<ng-template #customCellTemplate let-day="day" let-locale="locale" let-tooltipTemplate="tooltipTemplate">
  <div
    class="cal-cell-wrapper cbw-calendar-cell-wrapper"
    [ngbTooltip]="highlightedDatesDataSet?.[day.date | date: 'yyyy-MM-dd']?.data ? highlightDescription : undefined"
    [ngClass]="highlightedDatesDataSet?.[day.date | date: 'yyyy-MM-dd']?.css"
  >
    <ng-template #highlightDescription>
      <span
        [innerHTML]="highlightedDatesDataSet?.[day.date | date: 'yyyy-MM-dd']?.data | translation: 'description' : lang  | trustHtml"
      ></span>
    </ng-template>
    <div class="cal-cell-top">
      <div
        class="cal-day-number"
        [ngStyle]="getColorOfDay(day) | async"
        openDelay="500"
      >
        <div [ngClass]="{ 'calendar-total-badge' : day.badgeTotal > 0 }">
          <span>{{ day.date | calendarDate:'monthViewDayNumber' : locale }}</span>
          @if (day.badgeTotal > 0 && day.cssClass === 'cal-selected') {
            <span
              class="badge badge-primary custom-slots-badge"
              [ngClass]="{
                'single-digit-badge-adjustment': (day.date | calendarDate : 'monthViewDayNumber' : locale) < 10,
                'double-digit-badge-adjustment': (day.date | calendarDate : 'monthViewDayNumber' : locale) >= 10
              }"
            >
              {{ day.badgeTotal }}
            </span>
          }
        </div>
      </div>
    </div>
  </div>
</ng-template>

@if (!calendarLoaded) {
  <div class="loader-box">
    <app-loader [loaderType]="appointmentConstant.WORKER_LOADER" />
  </div>
}

<div [ngClass]="{'d-none': !calendarLoaded}">
  <mwl-calendar-month-view
    activeDayIsOpen=true
    weekStartsOn=1
    [viewDate]="viewDate"
    [weekendDays]="[]"
    [headerTemplate]="shortDays"
    [cellTemplate]="customCellTemplate"
    [refresh]="refreshCalendar"
    (dayClicked)="dayClicked($event)"
    (beforeViewRender)="markExceptionDays($event);"
  />
  <br />
</div>
